body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
  /*  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
  /*  sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.loading-bar {
  height: 20px;
  background-color: red;
}

@font-face {
  font-family: 'menu-icons';
  src: url('assets/fonts/menu-icons.eot?8to2el');
  src: url('assets/fonts/menu-icons.eot?8to2el#iefix')
      format('embedded-opentype'),
    url('assets/fonts/menu-icons.ttf?8to2el') format('truetype'),
    url('assets/fonts/menu-icons.woff?8to2el') format('woff'),
    url('assets/fonts/menu-icons.svg?8to2el#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'menu-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-home:before {
  content: '\e902';
  color: #334d6e;
}

.icon-accruals:before {
  content: '\e900';
  color: #334d6e;
}

.icon-payments:before {
  content: '\e906';
  color: #334d6e;
}

.icon-catalog:before {
  content: '\e901';
  color: #334d6e;
}
.icon-profile:before {
  content: '\e907';
  color: #334d6e;
}
.icon-logout:before {
  content: '\e903';
  color: #334d6e;
}
.icon-meters:before {
  content: '\e904';
  color: #334d6e;
}
.icon-news:before {
  content: '\e905';
  color: #334d6e;
}
.icon-requests:before {
  content: '\e908';
  color: #334d6e;
}
.icon-tickets:before {
  content: '\e909';
  color: #334d6e;
}

/* Шрифт из вордпрессовского сайта */

@font-face {
  font-family: 'flat-icons';
  src: url('assets/fonts/Flaticon.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='flat-icon-'],
[class*=' flat-icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'flat-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.flat-icon-sub:before {
  content: '\e91c';
  color: #334d6e;
}

#root {
  height: 100%;
  /* не сдвигает контент влево при появлении вертикального скроллбара */
  margin-left: calc(100vw - 100%);
  margin-right: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


input[type=number] {
  -moz-appearance: textfield;
}
